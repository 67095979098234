import {tns,} from 'tiny-slider/src/tiny-slider';

const fullCarousels = document.querySelectorAll('[data-carousel-full]');
if (0 != fullCarousels.length) {
  let carouselFull = [];
  [].forEach.call(fullCarousels, (elem, index) => {
    const carouselName = elem.dataset.carouselFull;
    carouselFull[index] = tns({
      container: elem,
      controlsContainer: document.querySelector('[data-carousel-btns="' + carouselName +'"]'),
      navContainer: document.querySelector('[data-carousel-dots="' + carouselName +'"]'),
      items: 1,
      slideBy: 'page',
      arrowKeys: true,
      useLocalStorage: false,
      lazyload: true,
      responsive: {
        320: {
          gutter: 6,
        },
        1025: {
          gutter: 30,
        },
      },
    });
  })
}

const beanCarousels = document.querySelectorAll('[data-carousel-bean]');
if (0 != beanCarousels.length) {
  let carouselBean =[];
  [].forEach.call(beanCarousels, (elem, index) => {
    const carouselName = elem.dataset.carouselBean;
    const controls = document.querySelector('[data-carousel-btns="' + carouselName +'"]');
    const dots = document.querySelector('[data-carousel-dots="' + carouselName +'"]');
    const dotsMobile = document.querySelector('[data-carousel-dots-mobile="' + carouselName +'"]');
    let options = {}
    if (window.matchMedia("(min-width: 64rem)").matches && 3 < elem.querySelectorAll('.crtCarouselBean-item').length) {
      options = {
        loop: false,
        touch: true,
        container: elem,
        controlsContainer: controls,
        navContainer: dots,
        arrowKeys: true,
        useLocalStorage: false,
        nav: true,
        lazyload: true,
        responsive: {
          1024: {
            slideBy: 3,
            gutter: 30,
            items: 3,
          },
        },
      }
      carouselBean[index] = tns(options);
    } else if ((window.matchMedia("(max-width: 64rem)").matches && 1 < elem.querySelectorAll('.crtCarouselBean-item').length)) {
      options = {
        loop: false,
        touch: true,
        container: elem,
        controlsContainer: controls,
        navContainer: dotsMobile,
        arrowKeys: true,
        useLocalStorage: false,
        nav: true,
        lazyload: true,
        responsive: {
          320: {
            slideBy: 1,
            gutter: 20,
            items: 1,
          },
        },
      }
      carouselBean[index] = tns(options);
    } else {
      controls.remove();
      dots.remove();
    }
  })
}

const smallCarousels = document.querySelectorAll('[data-carousel-product]');
if (0 != smallCarousels.length) {
  let carouselFb = [];
  [].forEach.call(smallCarousels, (elem, index) => {
    const carouselName = elem.dataset.carouselFranceBillet;
    const controls = document.querySelector('[data-carousel-btns="' + carouselName +'"]');
    const dots = document.querySelector('[data-carousel-dots="' + carouselName +'"]');

    const nav = document.querySelector('[data-carousel-dots="' + carouselName +'"]')
    if (1 < elem.querySelectorAll('.crtCarouselFranceBillet-item').length) {
      carouselFb[index] = tns({
        loop: false,
        container: elem,
        controlsContainer: controls,
        navContainer: nav,
        items: 1,
        slideBy: 'page',
        arrowKeys: true,
        useLocalStorage: false,
        lazyload: true,
      });
    } else  {
      controls.remove();
      dots.remove();
    }
  })
}
